/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    section: "section",
    h2: "h2",
    div: "div",
    strong: "strong",
    h3: "h3",
    ul: "ul",
    li: "li",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Recently, I added TypeScript to my personal project, ", React.createElement(_components.a, {
    href: "https://github.com/AndyN9/payday-calendar-generator",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Payday Calendar Generator"), ", a React project using Vite as the bundler. Here's what I learned:"), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "initial-project-changes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#initial-project-changes",
    "aria-label": "initial project changes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Initial Project Changes"), React.createElement(_components.p, null, "These steps only work for existing React projects using Vite. Conveniently, it's a handful of steps since Vite supports TypeScript really well."), React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " This tutorial will utilize the official ", React.createElement(_components.a, {
    href: "https://stackblitz.com/edit/vitejs-vite-s7vyi2?file=index.html&terminal=dev",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vite + React + TS StackBlitz"), " for files and references."), React.createElement(_components.section, null, React.createElement(_components.h3, {
    id: "update-configuration-files",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#update-configuration-files",
    "aria-label": "update configuration files permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Update configuration files"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Create TypeScript config files (", React.createElement(_components.code, null, "tsconfig.json"), " and ", React.createElement(_components.code, null, "tsconfig.node.json"), ") in project root", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Copy the respective config content from the StackBlitz example"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Refactor Vite config file from ", React.createElement(_components.code, null, "vite.config.js"), " to ", React.createElement(_components.code, null, "vite.config.ts")), "\n", React.createElement(_components.li, null, "Create a ", React.createElement(_components.code, null, "src/vite-env.d.ts"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Copy the respective config content from the StackBlitz example"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Modify ", React.createElement(_components.code, null, "package.json"), "'s ", React.createElement(_components.code, null, "npm run build script"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Update from ", React.createElement(_components.code, null, "\"build\": \"vite build\","), " to ", React.createElement(_components.code, null, "\"build\": \"tsc && vite build\",")), "\n"), "\n"), "\n")), React.createElement(_components.section, null, React.createElement(_components.h3, {
    id: "update-entry-point",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#update-entry-point",
    "aria-label": "update entry point permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Update entry point"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Refactor ", React.createElement(_components.code, null, "main.jsx"), " to ", React.createElement(_components.code, null, "main.tsx")), "\n", React.createElement(_components.li, null, "Update ", React.createElement(_components.code, null, "index.html"), " to include ", React.createElement(_components.code, null, "main.tsx")), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " At this point, the Vite project should be ready for TypeScript compilation, but as always, test the project to see if anything is not configured correctly. Here's some additional task I had to do.")), React.createElement(_components.section, null, React.createElement(_components.h3, {
    id: "additional-tasks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#additional-tasks",
    "aria-label": "additional tasks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Additional tasks"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Update ", React.createElement(_components.code, null, ".js"), " / ", React.createElement(_components.code, null, ".jsx"), " filename globs to include ", React.createElement(_components.code, null, ".ts"), " / ", React.createElement(_components.code, null, ".tsx"), " where necessary", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Like ", React.createElement(_components.code, null, "tailwind.config.js")), "\n"), "\n"), "\n"))), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "start-migration",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#start-migration",
    "aria-label": "start migration permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Start Migration"), React.createElement(_components.p, null, "Now the fun starts! My strategy was to update each file (and associated test file) to TypeScript one at a time. This flow was my guide for each file."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Rename file from ", React.createElement(_components.code, null, ".js"), " / ", React.createElement(_components.code, null, ".jsx"), " to ", React.createElement(_components.code, null, ".ts"), " / ", React.createElement(_components.code, null, ".tsx")), "\n", React.createElement(_components.li, null, "Convert CommonJS modules to ES6 modules"), "\n", React.createElement(_components.li, null, "Add types!"), "\n")), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), React.createElement(_components.p, null, "Vite is a blazing-fast bundler, and adding TypeScript to a Vite project will make development even more pleasant.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
